import classNames from "classnames"
import Link from "gatsby-link"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import "typeface-lato"
import TextContent from "../components/common/TextContent"
import Header from "../components/header/Header"
import Illustration from "../components/header/Illustration"
import Logo from "../components/header/Logo"
import Layout from "../components/layout"
import WhoIsThisToolFor from "../components/WhoIsThisToolFor"
import { getLinkToSubpage } from "../lib/utils"
import { resetState } from "../state/actions/app"
import styles from "../styles/content.module.styl"
import headerStyles from "../styles/header.module.styl"
import navigationStyles from "../styles/navigation.module.styl"

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => ({
  resetState: () => dispatch(resetState()),
})

class Template extends React.PureComponent {
  componentDidMount() {
    this.props.resetState()
  }

  render() {
    const { data, location, pathContext } = this.props
    const nodeData = data.decisionAidsJson
    const { pageId, subPage, groupId } = pathContext

    if (!nodeData) return null

    const nextLink = getLinkToSubpage(pageId, groupId, "facts")
    return (
      <Layout pageId={pageId} withGlossaryButton>
        <Header>
          <Logo />
          <div className={headerStyles.overlay}>
            <div className={headerStyles.title}>{nodeData.subPageTitle}</div>
          </div>
          <div className={headerStyles.illustrationContainer}>
            <Illustration logo={nodeData.logo} />
          </div>
        </Header>
        <div className={styles.content}>
          <TextContent content={nodeData.subTitle} className={styles.mb20} />
          <TextContent content={nodeData.introduction} />
          <h2 className={styles.sectionTitle}>{nodeData.question.title}</h2>
          <TextContent content={nodeData.question.content} />
          <h2 className={styles.sectionTitle}>{nodeData.timeline.title}</h2>
          <div>
            <img
              src={nodeData.timeline.image}
              style={{ width: "100%" }}
              alt="Timeline image"
            />
          </div>
          <WhoIsThisToolFor title={nodeData.whoIsThisToolFor.title} />
          <h2 className={styles.sectionTitle}>
            {nodeData.howToApproachTool.title}
          </h2>
          <TextContent content={nodeData.howToApproachTool.content} />
          <h2 className={styles.sectionTitle}>
            {nodeData.howThisToolCanHelp.title}
          </h2>
          <TextContent content={nodeData.howThisToolCanHelp.content} />
          <div
            className={classNames(
              navigationStyles.arrowNavigation,
              styles.justifyEnd
            )}
          >
            <Link
              className={classNames(
                navigationStyles.startArrow,
                navigationStyles.arrowRight
              )}
              to={nextLink}
            >
              <span className={navigationStyles.arrowText}>
                {"Let's start"}
              </span>
            </Link>
          </div>
        </div>
      </Layout>
    )
  }
}

Template.propTypes = {
  children: PropTypes.func,
}

export const query = graphql`
  query StartPageQuery($pageId: String) {
    decisionAidsJson(pageId: { eq: $pageId }) {
      logo
      title
      subPageTitle
      subTitle
      intervention
      comparison
      introduction
      question {
        title
        content
      }
      whoIsThisToolFor {
        title
      }
      timeline {
        title
        image
      }
      howToApproachTool {
        title
        content
      }
      howThisToolCanHelp {
        title
        content
      }
      chooseGroupHeader
    }
  }
`

export default connect(mapStateToProps, mapDispatchToProps)(Template)
